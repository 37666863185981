<template>
  <v-card class="ma-3">
    <base-loading v-if="loading.loading" />
    <div v-else class="content">
      <template>
        <v-card
          :class="{ 'card-success': cadastro, 'card-warn': !cadastro }"
          style="min-height: 60vh"
        >
          <h4 v-if="!cadastro" slot="header" class="card-title text-center">
            Edição da consultoria
          </h4>
          <h4 v-else slot="header" class="card-title text-center">
            Cadastro da consultoria
          </h4>
          <div class="clearfix" />
          <div>
            <v-form
              id="form"
              ref="form"
              v-model="valid"
              enctype="multipart/form-data"
              class="pa-3"
              lazy-validation
              @submit.prevent="updateOrCreate"
            >
              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Dados Pessoais
                  </v-card-title>
                  <v-row v-if="!loading.loading" class="pa-3">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.NomeFantasia"
                        label="Nome Fantasia *"
                        name="nome_fantasia"
                        :rules="Rules.required"
                        required
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="form.RazaoSocial"
                        label="Razão Social *"
                        :rules="Rules.required"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.email"
                        name="email"
                        label="Email *"
                        :rules="Rules.email"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.Cnpj"
                        v-mask="Masks.cnpj"
                        name="cnpj"
                        label="CNPJ *"
                        :rules="Rules.cnpj"
                        required
                      />
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="form.TelCel"
                        v-mask="Masks.telCel"
                        :rules="Rules.tel"
                        label="Telefone Celular *"
                        name="telefone"
                        type="tel"
                        required
                      />
                    </v-col>
                    <v-col cols="3">
                      <v-text-field
                        v-model="form.TelRes"
                        v-mask="Masks.telRes"
                        :rules="Rules.tel"
                        label="Telefone Comercial *"
                        type="tel"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-card>

              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Endereço
                  </v-card-title>

                  <base-address v-model="address" class="pa-3"></base-address>
                </v-list-item-content>
              </v-card>

              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Associados
                  </v-card-title>
                  <v-row class="pa-3">
                    <v-col cols="6" sm="4">
                      <v-autocomplete
                        v-model="form.agroindustrias"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Agroindústrias "
                        name="agroindustrias"
                        :items="agroindustrias"
                        :loading="loading.agroindustrias"
                        :disabled="loading.agroindustrias"
                        item-text="pessoa.pessoajuridica.NomeFantasia"
                        item-value="CodAgroindustria"
                        clearable
                        multiple
                        small-chips
                        @change="filter"
                        @click:clear="
                          () => {
                            form.agroindustrias = []
                            form.consultores = []
                            form.fazendas = []
                          }
                        "
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                            v-if="index < CHIP_LIMIT"
                            outlined
                            small
                            class="caption"
                          >
                            <span v-if="index < CHIP_LIMIT">
                              {{ item.pessoa.pessoajuridica.NomeFantasia }}
                            </span>
                          </v-chip>
                          <span
                            v-if="index === CHIP_LIMIT"
                            class="grey--text caption"
                          >
                            (+{{ form.agroindustrias.length - CHIP_LIMIT }}
                            outros)
                          </span>
                        </template>
                        <template slot="prepend-item">
                          <v-btn
                            text
                            class="green--text"
                            elevation="2"
                            color="blue"
                            block
                            small
                            @click="selectAll('agroindustrias')"
                          >
                            SELECIONAR TODOS
                          </v-btn>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="6" sm="4">
                      <v-autocomplete
                        v-model="form.consultores"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Consultores "
                        name="consultores"
                        :items="consultores"
                        :loading="loading.consultores"
                        :disabled="
                          loading.consultores == false &&
                          !form.agroindustrias.length
                        "
                        item-text="Nome"
                        item-value="id"
                        clearable
                        multiple
                        @change="filter"
                        @click:clear="
                          () => {
                            form.consultores = []
                            form.fazendas = []
                          }
                        "
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                            v-if="index < CHIP_LIMIT"
                            outlined
                            small
                            class="caption"
                          >
                            <span v-if="index < CHIP_LIMIT">
                              {{ item.Nome }}
                            </span>
                          </v-chip>
                          <span
                            v-if="index === CHIP_LIMIT"
                            class="grey--text caption"
                          >
                            (+{{ form.consultores.length - CHIP_LIMIT }}
                            outros)
                          </span>
                        </template>
                        <template slot="prepend-item">
                          <v-btn
                            text
                            class="green--text"
                            elevation="2"
                            color="blue"
                            block
                            small
                            @click="selectAll('consultores')"
                          >
                            SELECIONAR TODOS
                          </v-btn>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <v-col cols="6" sm="4">
                      <v-autocomplete
                        v-model="form.fazendas"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Fazendas "
                        name="fazendas"
                        :items="fazendas"
                        :loading="loading.fazendas"
                        :disabled="
                          loading.fazendas === false && !form.consultores.length
                        "
                        item-text="name"
                        item-value="id"
                        clearable
                        multiple
                        small-chips
                        @click:clear="() => (form.fazendas = [])"
                      >
                        <template v-slot:selection="{ item, index }">
                          <v-chip
                            v-if="index < CHIP_LIMIT"
                            outlined
                            small
                            class="caption"
                          >
                            <span v-if="index < CHIP_LIMIT">
                              {{ item.name }}
                            </span>
                          </v-chip>
                          <span
                            v-if="index === CHIP_LIMIT"
                            class="grey--text caption"
                          >
                            (+{{ form.fazendas.length - CHIP_LIMIT }}
                            outros)
                          </span>
                        </template>
                        <template slot="prepend-item">
                          <v-btn
                            text
                            class="green--text"
                            elevation="2"
                            color="blue"
                            block
                            small
                            @click="selectAll('fazendas')"
                          >
                            SELECIONAR TODOS
                          </v-btn>
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-card>

              <v-card>
                <v-list-item-content>
                  <v-card-title class="headline mx-auto">
                    Permissões
                  </v-card-title>
                  <v-row class="pa-3">
                    <v-col cols="6" sm="4">
                      <v-autocomplete
                        v-model="form.permissions"
                        autocomplete="autocomplete_off_hack_xfr4!k"
                        label="Relatórios "
                        name="permissions"
                        :items="permissions"
                        :loading="loading.permissions"
                        :disabled="loading.permissions"
                        item-text="name"
                        item-value="id"
                        clearable
                        multiple
                      />
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-card>

              <v-btn
                type="submit"
                :disabled="salvando"
                :loading="salvando"
                :color="!$route.query.CodGestor ? 'success' : 'warning'"
                form="form"
              >
                {{ btnNome }}
              </v-btn>

              <router-link
                :disabled="salvando"
                :to="`/${$user.get().role}/gestores`"
              >
                <v-btn :disabled="salvando" color="error" class="ml-3">
                  Cancelar
                </v-btn>
              </router-link>
            </v-form>
          </div>
        </v-card>
      </template>
    </div>
  </v-card>
</template>
<script>
import ConsultoriaService from '../../services/api/Consultoria'
import AgroindustriaService from '../../services/api/agroindustry'
import ConsultorService from '../../services/api/consultant'
import FazendaService from '../../services/api/farms'
import reportTypeService from '../../services/api/reportType'

import { Masks } from '../../utils/masks'
import { Rules } from '../../utils/rules'

export default {
  data() {
    return {
      Rules,
      Masks,
      salvando: false,

      loading: {
        loading: true,
        agroindustrias: false,
        fazendas: false,
        consultores: false,
        produtores: false,
        permissions: false,
      },

      valid: false,
      btnNome: !this.$route.query.id ? 'Salvar' : 'Salvar Alterações',
      form: {
        id: null,
        email: '',
        CodCidade: '',
        Cep: '',
        Bairro: '',
        Complemento: '',
        Logradouro: '',
        Numero: '',
        TelRes: '',
        TelCel: '',
        TelRec: '',
        Cnpj: '',
        RazaoSocial: '',
        NomeFantasia: '',
        permissions: [],
        agroindustrias: [],
        fazendas: [],
        consultores: [],
        produtores: [],
      },

      farmsFilter: [],

      e1: 1,

      address: {},
      permissions: [],
      agroindustrias: [],
      fazendas: [],
      consultores: [],

      allAgroindustrias: [],
      allFazendas: [],
      allConsultores: [],

      cadastro: this.$route.query.id === 0 ? 1 : 0,

      CHIP_LIMIT: 5,
    }
  },

  created() {
    if (this.$route.query.id) {
      this.form.id = this.$route.query.id
      this.loadForm()
    } else {
      this.loadNewForm()
    }
  },
  methods: {
    loadNewForm() {
      this.loadData()
      this.loading.loading = false
    },

    async loadForm() {
      this.loadData()

      ConsultoriaService.show(this.$route.query.id)
        .then(response => {
          const data = response.data.data
          this.form = data

          this.address = {
            CodCidade: data.CodCidade,
            Cep: data.Cep,
            Bairro: data.Bairro,
            Complemento: data.Complemento,
            Logradouro: data.Logradouro,
            Numero: data.Numero,
          }
        })
        .catch(error => {
          this.Toast().fire({
            icon: 'error',
            title: 'Erro ao carregar consultoria',
            html: error.response.data.message,
          })
        })
        .finally(() => {
          this.loading.loading = false
        })
    },

    updateOrCreate() {
      this.valid = this.$refs.form.validate()

      const permissions = this.form.permissions.map(permission => {
        return { report_id: permission }
      })

      if (!this.valid) {
        return this.Toast().fire({
          icon: 'warning',
          title: 'Preencha os itens obrigatórios sinalizados com ("*").',
        })
      }

      const form = {
        ...this.form,
        ...this.address,
        permissions,
      }

      if (form.id) {
        this.update(form)
      } else {
        this.create(form)
      }
    },

    async create(form) {
      this.salvando = true

      ConsultoriaService.create(form)
        .then(() => {
          return this.Swal.fire({
            title: 'Sucesso',
            text:
              'Consultoria cadastrada com sucesso! Um email foi enviado para o email cadastro para definir a senha.',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            this.$router.push({
              path: `/${this.$user.get().role}/consultancy`,
            })
          })
        })
        .catch(error => {
          this.Toast().fire({
            icon: 'error',
            title:
              error.response.data.message ?? 'Erro ao cadastrar consultoria',
            html: error.response.data.data,
          })
        })
        .finally(() => {
          this.salvando = false
        })
    },

    async update(form) {
      this.salvando = true

      try {
        const response = await ConsultoriaService.update(form)

        if (response) {
          this.Swal.fire({
            title: 'Sucesso',
            text: 'Consultoria atualizada com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
          }).then(() => {
            this.$router.push({
              path: `/${this.$user.get().role}/consultancy`,
            })
          })
        }
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao atualizar consultoria',
        })
      } finally {
        this.salvando = false
      }
    },

    async loadData() {
      this.loading.consultores = true
      this.loading.agroindustrias = true
      this.loading.fazendas = true
      this.loading.consultores = true
      this.loading.permissions = true

      try {
        const permissions = await reportTypeService.list()
        this.permissions = permissions
        this.loading.permissions = false

        const agroindustrias = await AgroindustriaService.list()
        this.agroindustrias = agroindustrias
        this.allAgroindustrias = agroindustrias
        this.loading.agroindustrias = false

        const consultores = await ConsultorService.list()
        this.consultores = consultores
        this.allConsultores = consultores
        this.loading.consultores = false

        const fazendas = await FazendaService.list()
        this.fazendas = fazendas
        this.allFazendas = fazendas
        this.loading.fazendas = false

        this.filter()
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao carregar consultoria',
        })
      }
    },

    selectAll(type) {
      this.form[type] = this[type].map(item => item.id)

      this.filter()
    },

    filter() {
      if (this.form.agroindustrias) {
        const result = this.agroindustrias
          .filter(agro => {
            return this.form.agroindustrias.includes(agro.CodAgroindustria)
          })
          .map(agro => {
            return agro.agroindustria_fazenda
              .map(fazenda => {
                if (fazenda.DataFim) {
                  return null
                }

                return fazenda.CodFazenda
              })
              .flat()
          })

        this.farmsFilter = result.flat()
      } else {
        this.farmsFilter = []
      }

      const codConsultores = this.farmsFilter
        .map(
          codFazenda =>
            this.allFazendas.find(fazenda => fazenda.CodFazenda === codFazenda)
              ?.CodConsultorTecnico,
        )
        .filter(cod => cod)

      this.consultores = this.allConsultores.filter(consultor => {
        return codConsultores.includes(consultor.id)
      })

      if (!this.consultores.length) {
        this.form.consultores = []
      }

      const fazendasConsultores = this.allFazendas.filter(fazenda => {
        const consultor = this.form.consultores.find(
          consultor => consultor === fazenda.CodConsultorTecnico,
        )

        const agroindustria = this.form.agroindustrias.find(
          agroindustria =>
            agroindustria === fazenda?.agroindustria_ativa?.CodAgroindustria,
        )

        if (consultor && agroindustria) return fazenda
      })

      this.fazendas = fazendasConsultores
    },
  },
}
</script>
