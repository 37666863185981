export const celular = str =>
  str
    .replace(/\D/g, '')
    .substring(0, 11)
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2')

export const convertNumberBR = number => {
  return number
    .toFixed(2)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const Masks = {
  cnpj: '##.###.###/####-##',
  telCel: '(##) # ####-####',
  cep: '##.###-###',
  telRes: '(##) ####-####',
}
