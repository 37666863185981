export const currencyFormatter = (number, prefix = null) => {
  if (!number) number = 0

  const res = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    currency: 'BRL',
    maximumFractionDigits: 2,
  }).format(String(number))

  if (prefix) {
    return prefix + ' ' + res
  }

  return res
}

export const dateFormatter = string => {
  return (
    string.substring(8, 10) +
    '/' +
    string.substring(5, 7) +
    '/' +
    string.substring(0, 4)
  )
}

export const datetimeFormatter = string => {
  return (
    string.substring(8, 10) +
    '/' +
    string.substring(5, 7) +
    '/' +
    string.substring(0, 4) +
    ' ' +
    string.substring(11, 13) +
    ':' +
    string.substring(14, 16) +
    ':' +
    string.substring(17, 19)
  )
}

export const normalizeStr = string => {
  return String(string)
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
}

export function _orderByName({ data, key }) {
  return data?.sort(function (first, last) {
    return String(first[key]).localeCompare(last[key])
  })
}

export const daysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate()
}

export function showMoney(value) {
  if (typeof value === 'string')
    if (value.indexOf('.') && value.indexOf(',') > -1) {
      const [integer] = value.split(',')
      return currencyFormatter(integer.replace('.', ''))
    }

  return currencyFormatter(String(value).replace(',', '.'))
}
