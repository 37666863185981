import api from '../../../api/api'
import { _orderByName } from '../../../utils/formatter'

export default {
  list: async () => {
    let data = await api.get.consultores()

    return _orderByName({
      data,
      key: 'Nome',
    })
  },

  getConsultores: async ({ active = null }) => {
    const data = await api.get.getConsultores(active)

    return _orderByName({
      data,
      key: 'Nome',
    })
  },

  listDataReports: async ({ DataInicio, DataFim, reportId }) => {
    const data = await api.get.reportData({ DataInicio, DataFim, reportId })

    return data
  },

  getAllProdutores: async ({ id }) => {
    const data = await api.get.getProdutores({ id })

    return data
  },

  financeiro: {
    async create({ form }) {
      const data = await api.save.createConsultorFinacial(form)

      return data
    },

    async update({ id, form }) {
      const data = await api.update.updateConsultorFinacial(id, form)

      return data
    },

    async list() {
      const data = await api.get.consultorFinacial()

      return data
    },

    async find({ id }) {
      const data = await api.get.consultorFindFinacial(id)

      return data
    },

    async delete({ id }) {
      const data = await api.delete.consultorFinacial(id)

      return data
    },
  },
}
