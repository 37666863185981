import { validarCNPJ } from './validacoes'

export const Rules = {
  cnpj: [v => !!v || '* Obrigatório', v => validarCNPJ(v) || '* CNPJ inválido'],

  required: [v => !!v || '* Obrigatório'],

  tel: [
    v => !v || v.length === 0 || v.length >= 10 || '* Número inválido',
    v => !!v || '* Obrigatório',
  ],

  email: [v => /.+@.+/.test(v) || 'E-mail inválido'],
}
