import axios from '../../../api/axios'

export default {
  list: async () => {
    return await axios.get('/consultoria')
  },

  show: async id => {
    return await axios.get(`/consultoria/${id}`)
  },

  create: async data => {
    return await axios.post('/consultoria', data)
  },

  update: async data => {
    return await axios.put(`/consultoria/${data.id}`, data)
  },

  delete: async id => {
    return await axios.delete(`/consultoria/${id}`)
  },
}
